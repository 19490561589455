<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="0">
  <mat-label *ngIf="!editMode">{{ labelName }}</mat-label>
  <mat-form-field class="mat-form-field" appearance="outline" *ngIf="editMode">
    <mat-label>{{ labelName }}</mat-label>
    <input matInput [formControl]="inputText" (keydown.enter)="add()" />
    <button
      matSuffix
      mat-icon-button
      [disabled]="!inputText.valid"
      (click)="add()"
    >
      <mat-icon class="mat-icon add">add_circle</mat-icon>
    </button>
  </mat-form-field>
  <mat-list *ngIf="myController?.value.length > 0">
    <ng-container *ngFor="let name of myController?.value">
      <mat-list-item
        style="max-height: 30px"
        *ngIf="(name ?? '').trim().length > 0"
      >
        <mat-icon class="mat-icon add" matListIcon>check</mat-icon>
        <div style="font-size: 12px" mat-line [matTooltip]="name">
          {{ name }}
        </div>
        <button mat-icon-button (click)="remove(name)" *ngIf="editMode">
          <mat-icon class="mat-icon remove" mat-list-icon>cancel</mat-icon>
        </button>
      </mat-list-item>
    </ng-container>
  </mat-list>
</div>
