import { Component, OnInit } from '@angular/core';
import { SystemService } from '@core/services/common';
import { SeoService } from '@core/services/seo';
import { ThemeService } from '@core/services/theme';
import AuthService from '@pages/auth/services/auth.service';
import { ConnectionService } from 'ng-connection-service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoggedIn$!: Observable<boolean>;
  status = 'ONLINE';
  isConnected = true;

  constructor(
    private seoService: SeoService,
    private themeService: ThemeService,
    private authService: AuthService,
    private connectionService: ConnectionService,
    private systemService: SystemService,
  ) {
    this.connectionService.monitor().subscribe((isConnected) => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = 'ONLINE';
        // $("input").prop("disabled", false);
        // $(':button').prop('disabled', false);
        window.location.reload();
        this.systemService.showSnackBar(
          'Your internet is back, We recommend you to refresh the page for proper functioning',
          'Ok',
        );
      } else {
        this.status = 'OFFLINE';
        // $("input").prop("disabled", true);
        // $(':button').prop('disabled', true);
        this.systemService.showSnackBar(
          'No Internet Connection or your internet is not stable',
          'Ok',
        );
      }
    });
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isLoggedIn$;
    this.runGlobalServices();
  }

  private runGlobalServices(): void {
    this.seoService.init();
    this.themeService.init();
  }
}
