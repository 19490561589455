export const API_ROUTE: ApiListParams = {
  authLogin: 'YXV0aA==', //auth
  authLogout: 'YXV0aC9sb2dvdXQv', //auth/logout/
  authRole: 'cm9sZS9wZXJtaXNzaW9ucy8=', //role/permissions/
  changePartnerPassword: 'cGFydG5lci9wYXNzd29yZA==', //partner/password
  company: 'cHJvZHVjdC9jb21wYW55', //product/company
  companySearch: 'cHJvZHVjdC9jb21wYW55L3NlYXJjaA==', //product/company/search
  companyGet: 'cHJvZHVjdC9jb21wYW55L2dldA==', //product/company/get
  offer: 'b2ZmZXJz', //offers
  partner: 'cGFydG5lcg==', //partner
  partnerSearch: 'cGFydG5lci9zZWFyY2g=', //partner/search
  s3MultipleUpload: 'bWVkaWEvczMvdG9rZW4vbXVsdGlwbGU=', //media/s3/token/multiple
  s3SingleUpload: 'bWVkaWEvczMvdG9rZW4=', //media/s3/token
  searchUser: 'c2VhcmNo', //search
  updatePartner: 'cGFydG5lcg==', //partner
  userManagement: 'dXNlcg==', //user
  warrantyCategory: 'd2FycmFudHljYXRlZ29yeQ==', //warrantycategory
  warrantyCategorySearch: 'd2FycmFudHljYXRlZ29yeS9zZWFyY2g=', //warrantycategory/search
  warrantyOffer: 'b2ZmZXJzL3NlYXJjaA==', //offers/search,
  warrantyTypeSearch: 'd2FycmFudHl0eXBlL3NlYXJjaA==', //warrantytype/search
  warrantyType: 'd2FycmFudHl0eXBl', //warrantytype
  warrantySellerTypeMappingSearch: 'd2FycmFudHl0eXBlbWFwL3NlYXJjaA==', //warrantytypemap/search
  warrantySellerTypeMapping: 'd2FycmFudHl0eXBlbWFw', //warrantytypemap
  warrantyDeviceSearch: 'd2FycmFudHlkZXZpY2Uvc2VhcmNo', //warrantydevice/search
  warrantyDevice: 'd2FycmFudHlkZXZpY2U=', //warrantydevice
  warrantyDevicePriceRangeSearch: 'd2FycmFudHlwcmljZXJhbmdlL3NlYXJjaA==', //warrantypricerange/search
  warrantyDevicePriceRange: 'd2FycmFudHlwcmljZXJhbmdl', //warrantypricerange
  warrantyPlanSearch: 'd2FycmFudHlwbGFuL3NlYXJjaA==', //warrantyplan/search
  warrantyPlan: 'd2FycmFudHlwbGFu', //warrantyplan
  warrantyOrderSearch: 'b3JkZXIvd2FycmFudHkvc2VhcmNo', //order/warranty/search
  warrantyOrder: 'b3JkZXIvd2FycmFudHk=', // order/warranty
  warrantyStatusUpdate: 'b3JkZXIvd2FycmFudHkvY2hhbmdlc3RhdHVz', //order/warranty/changestatus
  warrantyOrderRefund: 'b3JkZXIvd2FycmFudHkvcmVmdW5k', //order/warranty/refund
  otpManagerSearch: 'b3RwL2hlbHBlci9zZWFyY2g=', //otp/helper/search
  otpManagerCreate: 'b3RwL2hlbHBlci9jcmVhdGU=', //otp/helper/create
  receipts: 'cmVjZWlwdHM=', //receipts
  receiptSearch: 'cmVjZWlwdHMvc2VhcmNo', //receipts/search
};

type ApiListParams = {
  [key: string]: string;
};
