export const ROUTER_UTILS = {
  CONFIG: {
    BASE: {
      DASHBOARD: 'dashboard',
    },
    AUTH: {
      ROOT: 'auth',
      SIGNIN: 'sign-in',
    },
    HOME: {
      ROOT: 'home',
    },
    USER: {
      ROOT: 'user',
      UPDATE: 'edit',
      CREATE: 'create',
      READ: 'view',
    },
    PARTNER: {
      ROOT: 'partner',
      UPDATE: 'edit',
      CREATE: 'create',
      READ: 'view',
    },
    COMPANY: {
      ROOT: 'company',
      UPDATE: 'edit',
      CREATE: 'create',
      READ: 'view',
    },
    WARRANTY: {
      ROOT: 'warranty',
      WARR_CAT: {
        ROOT: 'category',
        UPDATE: 'edit',
        CREATE: 'create',
        READ: 'view',
      },
      WARR_TYPE: {
        ROOT: 'type',
        UPDATE: 'edit',
        CREATE: 'create',
        READ: 'view',
      },
      WARR_TYPE_MAP: {
        ROOT: 'seller-type-mapping',
        UPDATE: 'edit',
        CREATE: 'create',
        READ: 'view',
      },
      WARR_DEV: {
        ROOT: 'warranty-device',
        UPDATE: 'edit',
        CREATE: 'create',
        READ: 'view',
      },
      WARR_DEV_PR: {
        ROOT: 'warranty-device-price-range',
        UPDATE: 'edit',
        CREATE: 'create',
        READ: 'view',
      },
      WARR_PLAN: {
        ROOT: 'warranty-plan',
        UPDATE: 'edit',
        CREATE: 'create',
        READ: 'view',
      },
    },
    COMP_OFFERS: {
      ROOT: 'offer',
      CREATE: 'create',
      UPDATE: 'edit',
      READ: 'view',
    },
    WARR_ORDER: {
      ROOT: 'warranty-order',
      CREATE: 'create',
      UPDATE: 'edit',
      READ: 'view',
    },
    OTP_HELPER: {
      ROOT: 'otp-manager',
      CREATE: 'create',
    },
    RECEIPT: {
      ROOT: 'receipt-manager',
      UPDATE: 'edit',
      READ: 'view',
    },
    SETTINGS: {
      ROOT: 'settings',
    },
    ERROR_RESPONSE: {
      NOT_FOUND: '404',
    },
  },
};
