import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  readonly atleastOneCharacterRegex = /\S+/;
  readonly contactNumberRegex = /^\d{10}$/;
  readonly emailRegex = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;
  readonly emailMobileRegex =
    /^(\d{10}|\w+([/.-]?\w+)*@\w+([/.-]?\w+)*(\.\w{2,3}))$/;
  readonly numberRegex = /^\d+$/;
  readonly onlyAlphabetsRegex = /^[a-zA-Z _'-]*$/;
  readonly passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+_~]).{6,}$/;
  readonly urlRegex = /^((?:https?:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)$/;

  formatDate(date: string | number | Date, format: string): string {
    return formatDate(date, format, 'en-IN');
  }
}
