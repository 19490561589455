<!-- HTTP Progress Bar -->
<mat-progress-bar
  mode="indeterminate"
  *ngIf="loaderService.isHttpLoading$ | async"
></mat-progress-bar>
<app-header (toogleButtonClicked)="drawer.toggle()"></app-header>

<mat-drawer-container>
  <mat-drawer #drawer mode="side" opened>
    <mat-list role="list">
      <ng-container *ngFor="let menu of menuItems">
        <mat-list-item
          role="listitem"
          [class]="currentURL.includes(menu?.url ?? '') ? 'active' : ''"
          [routerLink]="menu.link"
          *ngIf="!menu.isNested; else nestedList"
        >
          <mat-icon mat-list-icon>{{ menu.icon }}</mat-icon>
          <div mat-line class="!text-sm">{{ menu.name }}</div>
        </mat-list-item>

        <!-- Nested List -->
        <ng-template #nestedList>
          <mat-list-item
            [class.open]="menu.open"
            (click)="menu.open = !menu.open"
          >
            <mat-icon mat-list-icon>{{ menu.icon }}</mat-icon>
            <div mat-line class="!text-sm">{{ menu.name }}</div>
            <mat-icon class="sidenav-dropdown-arrow">expand_more</mat-icon>
          </mat-list-item>

          <mat-nav-list
            class="nested-list"
            [style.maxHeight]="menu.open ? '100vh' : '0px'"
          >
            <mat-list-item
              [class]="
                currentURL.includes(subMenuItem?.url ?? '') ? 'active' : ''
              "
              [routerLink]="subMenuItem.link"
              *ngFor="let subMenuItem of menu.sub"
            >
              <mat-icon mat-list-icon>{{ subMenuItem.icon }}</mat-icon>
              <div mat-line class="!text-sm">{{ subMenuItem.name }}</div>
            </mat-list-item>
          </mat-nav-list>
        </ng-template>
      </ng-container>
    </mat-list>
  </mat-drawer>
  <mat-drawer-content class="main-scroll-div">
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>

<ngx-spinner type="ball-fussion"></ngx-spinner>
