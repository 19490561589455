import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_ROUTE, CommonService } from '@core/services/common';
import { getItem, StorageItem } from '@core/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root',
})
export default class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private aclSubject: BehaviorSubject<any>;
  public BASE_URL = '';
  isLoggedIn$ = new BehaviorSubject<boolean>(!!getItem(StorageItem.Auth));

  constructor(
    private http: HttpClient,
    private router: Router,
    private commonService: CommonService,
  ) {
    this.BASE_URL = this.commonService.BASE_URL;
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser')!),
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.aclSubject = new BehaviorSubject<any>(null);
  }

  public get currentUserValue(): User {
    this.currentUserSubject.next(
      JSON.parse(localStorage.getItem('currentUser')!),
    );
    return this.currentUserSubject.getValue();
  }

  public get userACL() {
    return this.aclSubject.getValue();
  }

  currenteUserValue(user: any) {
    this.currentUserSubject.next(user);
  }

  getRoles(roles: any) {
    this.aclSubject.next(roles);
  }

  authLogin(loginRequest: User): Observable<any> {
    return this.http.post<any>(
      this.BASE_URL + atob(API_ROUTE['authLogin']),
      loginRequest,
    );
  }

  getPermissions(roleName: any) {
    return this.http
      .get<any>(this.BASE_URL + atob(API_ROUTE['authRole']) + roleName)
      .toPromise();
  }

  authLogOut(formData: any, userId: string, type: 'sup_admin' | 'user') {
    return this.http.delete(
      this.BASE_URL + atob(API_ROUTE['authLogout']) + type,
      {
        headers: this.commonService.getHeaders({ user_id: userId }),
        body: formData,
      },
    );
  }

  signOut(): void {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next({});
    this.router.navigate(['/']);
  }
}
