import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, NoAuthGuard } from '@core/guards';
import { ROUTER_UTILS } from '@core/utils/router.utils';
import { NotFoundModule } from '@shell/ui/not-found/not-found.module';
import { FooterModule } from './ui/footer/footer.module';
import { HeaderModule } from './ui/header/header.module';
import { LayoutComponent } from './ui/layout/layout.component';
import { LayoutModule } from './ui/layout/layout.module';
import { NotFoundPage } from './ui/not-found/not-found.page';

const APP_ROUTES: Routes = [
  {
    path: ROUTER_UTILS.CONFIG.BASE.DASHBOARD,
    component: LayoutComponent,
    children: [
      {
        path: ROUTER_UTILS.CONFIG.HOME.ROOT,
        loadChildren: async () =>
          (await import('@pages/dashboard/dashboard.module')).DashboardModule,
        canActivate: [AuthGuard],
      },
      {
        path: ROUTER_UTILS.CONFIG.PARTNER.ROOT,
        loadChildren: async () =>
          (await import('@pages/partner/partner.module')).PartnerModule,
        canActivate: [AuthGuard],
      },
      {
        path: ROUTER_UTILS.CONFIG.COMPANY.ROOT,
        loadChildren: async () =>
          (await import('@pages/company/company.module')).CompanyModule,
        canActivate: [AuthGuard],
      },
      {
        path: ROUTER_UTILS.CONFIG.WARRANTY.ROOT,
        loadChildren: async () =>
          (await import('@pages/warranty/warranty.module')).WarrantyModule,
        canActivate: [AuthGuard],
      },
      {
        path: ROUTER_UTILS.CONFIG.COMP_OFFERS.ROOT,
        loadChildren: async () =>
          (await import('@pages/warranty-offer/warranty-offer.module'))
            .WarrantyOfferModule,
        canActivate: [AuthGuard],
      },
      {
        path: ROUTER_UTILS.CONFIG.WARR_ORDER.ROOT,
        loadChildren: async () =>
          (await import('@pages/order/warranty-order.module'))
            .WarrantyOrderModule,
        canActivate: [AuthGuard],
      },
      {
        path: ROUTER_UTILS.CONFIG.OTP_HELPER.ROOT,
        loadChildren: async () =>
          (await import('@pages/otp-manager/otp-manager.module'))
            .OTPManagerModule,
        canActivate: [AuthGuard],
      },
      {
        path: ROUTER_UTILS.CONFIG.RECEIPT.ROOT,
        loadChildren: async () =>
          (await import('@pages/receipt-manager/receipt-manager.module'))
            .ReceiptManagerModule,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '',
    loadChildren: async () =>
      (await import('@pages/auth/auth.module')).AuthModule,
    canActivate: [NoAuthGuard],
  },
  {
    path: ROUTER_UTILS.CONFIG.SETTINGS.ROOT,
    loadChildren: async () =>
      (await import('@pages/settings/settings.module')).SettingsModule,
    canActivate: [AuthGuard],
  },
  {
    path: ROUTER_UTILS.CONFIG.USER.ROOT,
    loadChildren: async () =>
      (await import('@pages/user/user.module')).UserModule,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    loadChildren: async () =>
      (await import('@shell/ui/not-found/not-found.module')).NotFoundModule,
    component: NotFoundPage,
  },
  {
    path: ROUTER_UTILS.CONFIG.AUTH.ROOT,
    loadChildren: async () =>
      (await import('@pages/auth/auth.module')).AuthModule,
    canActivate: [NoAuthGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(APP_ROUTES),
    FooterModule,
    HeaderModule,
    LayoutModule,
    NotFoundModule,
  ],
  exports: [
    RouterModule,
    FooterModule,
    HeaderModule,
    LayoutModule,
    NotFoundModule,
  ],
})
export class WebShellModule {}
