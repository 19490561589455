<footer
  class="
    container
    flex
    items-center
    justify-center
    flex-shrink-0
    h-12
    text-sm
    border-t border-neutral-200
    max-h-12
    dark:border-neutral-700
  "
>
  Built on planet 🌎
</footer>
