import { GlobalEnvironment } from './global.environment';

export const environment = {
  ...GlobalEnvironment,
  production: false,
  API_KEY: 'https://minion.ozo.club',
  X_API_KEY: '1bdd7a06-8a15-4d37-9676-4ffb873c73bf',
  X_API_SECRET: 'X76QMSdpK,PXgKaFIN3EJfc861]:HKHcoG0sh2V',
  SENTRY_DSN: '',
  AVAILABLE_LANGUAGE: ['en', 'es', 'hi'],
  DEFAULT_LANGUAGE: 'en',
  API_VERSION: '/v1/',
  ORIGIN: 'http://localhost:4200',
};
