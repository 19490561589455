export function DEVICE_DETECT(): deviceInterface {
  const navigator_info: any = window.navigator;
  const deviceType = navigator_info.userAgentData.platform;
  const screen_info = window.screen;
  let uid = navigator_info.mimeTypes.length;
  uid += navigator_info.userAgent.replace(/\D+/g, '');
  uid += navigator_info.plugins.length;
  uid += screen_info.height || '';
  uid += screen_info.width || '';
  uid += screen_info.pixelDepth || '';
  const deviceObj = {
    device_id: uid,
    device_type: deviceType,
    device_version: deviceType,
  };
  return deviceObj;
}

interface deviceInterface {
  device_id: string;
  device_type: string;
  device_version: string;
}
