import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DEVICE_DETECT } from '@core/utils/device-details.utils';
import { ROUTER_UTILS } from '@core/utils/router.utils';
import AuthService from '@pages/auth/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  path = ROUTER_UTILS.CONFIG.BASE;
  @Output() toogleButtonClicked: EventEmitter<void> = new EventEmitter();
  constructor(private router: Router, private authService: AuthService) {}

  signOut(): void {
    const formData = {
      device_id: DEVICE_DETECT().device_id,
      device_type: DEVICE_DETECT().device_type,
      device_version: DEVICE_DETECT().device_version,
    };
    const userId = this.authService.currentUserValue?.uid ?? '';
    this.authService.authLogOut(formData, userId, 'sup_admin').subscribe();
    this.authService.signOut();
    this.router.navigate([ROUTER_UTILS.CONFIG.AUTH.SIGNIN]);
  }
  openMenu() {
    this.toogleButtonClicked.emit();
  }
}
