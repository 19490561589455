import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SystemService } from '@core/services/common';
import { ROUTER_UTILS } from '@core/utils/router.utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import AuthService from '@pages/auth/services/auth.service';
import { map, switchMap } from 'rxjs/operators';
import { AuthActionTypes, LogIn, LogInSuccess } from '../actions/auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions: Actions,
    private authService: AuthService,
    private router: Router,
    private systemService: SystemService,
  ) {}

  // effects go here
  loginSuccess$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType<LogIn>(AuthActionTypes.LOGIN),
        map((action: LogIn) => action.payload),
        switchMap((payload) =>
          this.authService.authLogin(payload).pipe(
            map((user: any) => {
              console.log(user);
              this.systemService.showSuccessSnackBar(
                'Login Successfully',
                'OK',
              );
              this.router.navigate([
                '/' + ROUTER_UTILS.CONFIG.BASE.DASHBOARD,
                ROUTER_UTILS.CONFIG.HOME.ROOT,
              ]);
              this.authService
                .getPermissions(user.data.role)
                .then((res: any) => {
                  console.log(res);
                  this.authService.getRoles(res.data);
                });
              localStorage.setItem('currentUser', JSON.stringify(user.data));
              this.authService.currenteUserValue(user.data);

              return new LogInSuccess({ token: user });
            }),
          ),
        ),
      );
    },
    { dispatch: false },
  );
}
