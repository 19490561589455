import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { InitSentryConfig } from '@core/utils/sentry_logger';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  console.log('env?',environment.production)
  enableProdMode();
  InitSentryConfig();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log(`Bootstrap success`))
  .catch((err) => console.error(err));
