import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SystemService } from '@core/services/common/system.service';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ROUTER_UTILS } from '../utils/router.utils';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, public systemService: SystemService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    request = request.clone({
      withCredentials: true,
      setHeaders: {
        // 'x-api-key': decompress(environment.X_API_KEY),
        'x-api-key': environment.X_API_KEY,
        'x-api-secret': environment.X_API_SECRET,
      },
    });
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if ([401, 403].includes(error.status)) {
          this.router.navigateByUrl(ROUTER_UTILS.CONFIG.AUTH.SIGNIN);
          return throwError(error);
        } else {
          this.systemService.handleErrorResponse(error);
          return throwError(error);
        }
      }),
    );
  }
}
