import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  constructor(
    public snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private translocoService: TranslocoService,
  ) {}

  getorg() {
    return localStorage.getItem('orgid');
  }


  showSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: ['snack-bar'],
    });
  }

  showSuccessSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['success-snack-bar'],
    });
  }

  handleErrorResponse(error: any) {
    console.log(error);
    switch (error.status) {
      case 500:
        this.showSnackBar(this.translocoService.translate('ERRORS.500'), 'ok');
        break;
      case 400:
        this.showSnackBar(
          error.error.message ? error.error.message : error.message,
          'Ok',
        );
        break;
      case 404:
        this.showSnackBar(this.translocoService.translate('ERRORS.404'), 'ok');
        break;
      case 405:
        this.showSnackBar(this.translocoService.translate('ERRORS.405'), 'ok');
        break;
      case 401:
        this.showSnackBar(this.translocoService.translate('ERRORS.401'), 'ok');
        break;
      case 422:
        this.showSnackBar(
          error.error.message ? error.error.message : error.message,
          'Ok',
        );
        break;
      default:
        this.showSnackBar(
          this.translocoService.translate('ERRORS.UNKNOWN'),
          'ok',
        );
    }
  }
}
