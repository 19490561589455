import { ROUTER_UTILS } from '@core/utils/router.utils';
import { MenuItems } from 'src/app/models/menuItem';

const path = ROUTER_UTILS.CONFIG;
export const MENU_ITEM: MenuItems[] = [
  {
    icon: 'home',
    name: 'Home',
    link: `./${path.HOME.ROOT}`,
    url: path.HOME.ROOT,
    isNested: false,
  },
  {
    icon: 'sms',
    name: 'OTP Manager',
    link: `./${path.OTP_HELPER.ROOT}`,
    url: path.OTP_HELPER.ROOT,
    isNested: false,
  },
  {
    icon: 'receipt',
    name: 'Receipt Manager',
    link: `./${path.RECEIPT.ROOT}`,
    url: path.RECEIPT.ROOT,
    isNested: false,
  },
  {
    icon: 'store',
    name: 'Company',
    link: `./${path.COMPANY.ROOT}`,
    url: path.COMPANY.ROOT,
    isNested: false,
  },
  {
    icon: 'local_offer',
    name: 'Offer',
    link: `./${path.COMP_OFFERS.ROOT}`,
    url: path.COMP_OFFERS.ROOT,
    isNested: false,
  },
  {
    icon: 'group_work',
    name: 'Partner',
    link: `./${path.PARTNER.ROOT}`,
    url: path.PARTNER.ROOT,
    isNested: false,
  },
  {
    icon: 'receipt',
    name: 'Warranty Order',
    link: `./${path.WARR_ORDER.ROOT}`,
    url: path.WARR_ORDER.ROOT,
    isNested: false,
  },
  {
    icon: 'work',
    name: 'Warranty',
    isNested: true,
    sub: [
      {
        icon: 'work',
        name: 'Category',
        link: `./${path.WARRANTY.ROOT}/${path.WARRANTY.WARR_CAT.ROOT}`,
        url: path.WARRANTY.WARR_CAT.ROOT,
      },
      {
        icon: 'category',
        name: 'Type',
        link: `./${path.WARRANTY.ROOT}/${path.WARRANTY.WARR_TYPE.ROOT}`,
        url: path.WARRANTY.WARR_TYPE.ROOT,
      },
      {
        icon: 'device_hub',
        name: 'Seller Type Mapping',
        link: `./${path.WARRANTY.ROOT}/${path.WARRANTY.WARR_TYPE_MAP.ROOT}`,
        url: path.WARRANTY.WARR_TYPE_MAP.ROOT,
      },
      {
        icon: 'devices',
        name: 'Device',
        link: `./${path.WARRANTY.ROOT}/${path.WARRANTY.WARR_DEV.ROOT}`,
        url: path.WARRANTY.WARR_DEV.ROOT,
      },
      {
        icon: 'paid',
        name: 'Device Price Range',
        link: `./${path.WARRANTY.ROOT}/${path.WARRANTY.WARR_DEV_PR.ROOT}`,
        url: path.WARRANTY.WARR_DEV_PR.ROOT,
      },
      {
        icon: 'savings',
        name: 'Plan',
        link: `./${path.WARRANTY.ROOT}/${path.WARRANTY.WARR_PLAN.ROOT}`,
        url: path.WARRANTY.WARR_PLAN.ROOT,
      },
    ],
  },
];
