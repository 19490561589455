import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ROUTER_UTILS } from '@core/utils/router.utils';
import AuthService from '@pages/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;

    if (!currentUser) {
      return true;
    } else {
      this.router.navigate([
        ROUTER_UTILS.CONFIG.BASE.DASHBOARD,
        ROUTER_UTILS.CONFIG.HOME.ROOT,
      ]);
      return false;
    }
  }
}
