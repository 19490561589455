<mat-toolbar>
  <button
    mat-icon-button
    aria-label="Example icon-button with menu icon"
    (click)="openMenu()"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <img src="./../../../../assets/ozologo.png" class="mb-2 ml-2 w-14" />
  <span class="toolbar-spacer"></span>
  <button
    mat-icon-button
    class="example-icon favorite-icon"
    aria-label="Example icon-button with heart icon"
  >
    <mat-icon>bell</mat-icon>
  </button>
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <div class="avatar">
      <img
        src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
      />
    </div>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="signOut()">
      <mat-icon>logout</mat-icon>Logout
    </button>
  </mat-menu>
</mat-toolbar>
