import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  Observable,
  skip,
  startWith,
} from 'rxjs';

@Component({
  selector: 'app-filter-header',
  templateUrl: './filter-header.component.html',
  styleUrls: ['./filter-header.component.scss'],
})
export class FilterHeaderComponent implements OnInit {
  @Input() filterForm!: FormGroup;
  @Input() IsShowSearchBar = true;
  @Input() IsShowStatusFilter = true;
  @Input() IsShowPaymentStatusFilter = false;

  @Output() filterValuesChanged: EventEmitter<any> = new EventEmitter();

  @Input() paymentStatus: any[] = [];
  ngOnInit(): void {
    this.setEventListener();
  }
  private setEventListener() {
    const filterValueChangesList: Observable<any>[] = Object.entries(
      this.filterForm.controls,
    ).map(([key, control]) => {
      switch (key) {
        case 'q':
          return control.valueChanges.pipe(
            startWith(control.value ?? ''),
            map((value) => value?.trim()),
            debounceTime(250),
            distinctUntilChanged(),
            skip(1),
          );

        default:
          return control.valueChanges.pipe(
            startWith(control.value ?? null),
            debounceTime(500),
            distinctUntilChanged(),
            skip(1),
          );
      }
    });

    merge(...filterValueChangesList, this.filterForm.valueChanges)
      .pipe(debounceTime(250))
      .subscribe(() => {
        this.filterValuesChanged.emit(this.filterForm?.value);
      });
  }
}
