import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/models/api-response';
import { Partner } from '../../../models/partner';
import { API_ROUTE } from './common.config';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public BASE_URL: string = environment.API_KEY + environment.API_VERSION;

  constructor(public http: HttpClient, private handler: HttpBackend) {}

  getHeaders(obj?: any) {
    if (obj) {
      const headers = new HttpHeaders({
        ...obj,
        'Content-Type': 'application/json',
      });
      return headers;
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  getJsonFromFile(fileName: string): Observable<any> {
    return this.http.get<any>(`assets/json/${fileName}`);
  }

  postRequest(body: any, service: string): Observable<any> {
    return this.http.post<any>(this.BASE_URL + atob(API_ROUTE[service]), body);
  }

  getRequest(service: string): Observable<string> {
    return this.http.get<string>(this.BASE_URL + atob(API_ROUTE[service]));
  }

  patchRequest(id: any, body: any, service: string): Observable<any> {
    return this.http.patch<any[]>(
      this.BASE_URL + atob(API_ROUTE[service]) + '/' + id,
      body,
    );
  }

  putRequest(body: any, service: string): Observable<string> {
    return this.http.put<string>(
      this.BASE_URL + atob(API_ROUTE[service]),
      body,
    );
  }

  deleteRequest(id: string, service: string): Observable<string> {
    return this.http.post<string>(this.BASE_URL + atob(API_ROUTE[service]), id);
  }

  // Media Upload

  s3SingleUpload(
    body: object,
    userId: string,
    service: string,
  ): Observable<any> {
    const header = new HttpHeaders({
      user_id: userId,
    });
    return this.http.post<any>(this.BASE_URL + atob(API_ROUTE[service]), body, {
      headers: header,
    });
  }

  s3MultipleUpload(
    body: any,
    userId: string,
    service: string,
  ): Observable<any> {
    const headers = new HttpHeaders({
      user_id: userId,
    });
    return this.http.post<any>(this.BASE_URL + atob(API_ROUTE[service]), body, {
      headers,
    });
  }

  uploadSingleFileToS3(file: File, url: string): Observable<any> {
    this.http = new HttpClient(this.handler);
    let headers = new HttpHeaders();
    headers = headers.append('Origin', environment.ORIGIN);
    headers = headers.append('Access-Control-Request-Method', 'PUT');
    headers = headers.append('Content-Type', file.type);
    return this.http.put<Observable<any>>(url, file, { headers });
  }

  //

  // Partner Service

  getAllPartners(body: any, service: string): Observable<any> {
    return this.http.post<Partner[]>(
      this.BASE_URL + atob(API_ROUTE[service]),
      body,
    );
  }

  createNewPartner(body: Partner, service: string): Observable<Partner> {
    return this.http.post<Partner>(
      this.BASE_URL + atob(API_ROUTE[service]),
      body,
    );
  }

  changePassword(
    body: any,
    partner_id: string,
    service: string,
  ): Observable<ApiResponse<Partner>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      partner_id: partner_id,
    });
    return this.http.patch<ApiResponse<Partner>>(
      this.BASE_URL + atob(API_ROUTE[service]),
      body,
      {
        headers,
      },
    );
  }

  getOnePartner(id: any, service: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      partner_id: id,
    });
    return this.http.get<number>(this.BASE_URL + atob(API_ROUTE[service]), {
      headers,
    });
  }

  updatePartner(id: any, body: Partner, service: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      partner_id: id,
    });

    return this.http.patch<Partner>(
      this.BASE_URL + atob(API_ROUTE[service]),
      body,
      {
        headers,
      },
    );
  }

  //Warranty Offer
  getAllWarrantyOffers(body: any, service: string): Observable<any> {
    return this.http.post<any>(
      this.BASE_URL + atob(API_ROUTE[service]) + '/search',
      body,
    );
  }

  createWarrantyOffers(id: any, body: any, service: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      comp_id: id,
    });
    return this.http.post<any[]>(
      this.BASE_URL + atob(API_ROUTE[service]),
      body,
      {
        headers,
      },
    );
  }

  updateWarrantyOffers(id: any, body: any, service: string): Observable<any> {
    return this.http.patch<any[]>(
      this.BASE_URL + atob(API_ROUTE[service]) + '/' + id,
      body,
    );
  }

  public getOneDataById(id: any, service: any): Observable<any> {
    return this.http.get(this.BASE_URL + atob(API_ROUTE[service]) + '/' + id);
  }

  hardDelete(service: string, id: string): Observable<string> {
    return this.http.delete<any>(
      this.BASE_URL + atob(API_ROUTE[service]) + '/delete/' + id,
    );
  }
}
