<form
  [formGroup]="filterForm"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="20px"
>
  <mat-form-field
    appearance="outline"
    class="example-full-width"
    fxFlex="60"
    *ngIf="IsShowSearchBar"
  >
    <input
      formControlName="q"
      type="tel"
      matInput
      placeholder="Search"
      name="search"
    />
  </mat-form-field>

  <mat-form-field appearance="outline" fxFlex="20" *ngIf="IsShowStatusFilter">
    <mat-label>Filter</mat-label>
    <mat-select formControlName="status">
      <mat-option value="all">All</mat-option>
      <mat-option value="active">Active</mat-option>
      <mat-option value="inactive">Inactive</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    fxFlex="20"
    *ngIf="IsShowPaymentStatusFilter"
  >
    <mat-label>Payment Status</mat-label>
    <mat-select formControlName="status" multiple>
      <mat-option [value]="status.id" *ngFor="let status of paymentStatus">{{
        status.name
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</form>
