import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DirectivesModule } from './directives/directives.module';
import { ServerErrorInterceptor } from './interceptors';
import { LoaderInterceptor } from './interceptors/loader/loader.interceptor';
import { MaterialModule } from './material.module';
import { FilterHeaderComponent } from './shared/components/filter-header/filter-header.component';
import { MultipleInputsComponent } from './shared/components/multiple-inputs/multiple-inputs.component';

@NgModule({
  declarations: [FilterHeaderComponent, MultipleInputsComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    InfiniteScrollModule,
    FlexLayoutModule,
    NgxSpinnerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    InfiniteScrollModule,
    FlexLayoutModule,
    FilterHeaderComponent,
    MultipleInputsComponent,
    NgxSpinnerModule,
  ],
})
export class CoreModule {}
