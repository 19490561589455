import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NumberDirective } from './numbers-only.directive';

@NgModule({
  declarations: [NumberDirective],
  imports: [CommonModule],
  exports: [NumberDirective],
})
export class DirectivesModule {}
