import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ValidationService } from '@core/services/common/validation.service';

@Component({
  selector: 'app-multiple-inputs',
  templateUrl: './multiple-inputs.component.html',
  styleUrls: ['./multiple-inputs.component.scss'],
})
export class MultipleInputsComponent {
  constructor(private validationService: ValidationService) {}
  @Input()
  myController!: AbstractControl | null;

  @Input()
  labelName!: string;

  @Input() editMode = true;

  @Input() checkEmail = false;

  inputText = new FormControl('', {});

  add(): void {
    const value = (this.inputText.value || '').trim();

    if (this.checkEmail) {
      if (this.validationService.emailRegex.test(value)) {
        if (value) {
          this.myController?.value.push(value);
          this.inputText.reset();
        }
      }
    } else {
      if (value) {
        this.myController?.value.push(value);
        this.inputText.reset();
      }
    }
  }

  remove(name: string): void {
    const index = this.myController?.value.indexOf(name);
    if (index >= 0) {
      this.myController?.value.splice(index, 1);
    }
  }
}
