import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoaderService } from '@core/services/loader/loader.service';
import { MENU_ITEM } from '@core/shared/config/menu-item-list.config';
import { ROUTER_UTILS } from '@core/utils/router.utils';
import { filter } from 'rxjs/operators';
import { MenuItems } from 'src/app/models/menuItem';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  path = ROUTER_UTILS.CONFIG;
  menuItems: MenuItems[] = [];
  currentURL: string[] = [];
  navigationEnd!: any;

  constructor(public loaderService: LoaderService, private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((res) => {
        this.navigationEnd = res;
        const url = this.navigationEnd.url;
        this.currentURL = url.toString().split('/');
      });
  }
  ngOnInit(): void {
    this.menuItems = this.getMenuItems();
  }

  private getMenuItems(): MenuItems[] {
    return MENU_ITEM;
  }
}
